import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CarrinhoService } from '../services/carrinho.service';

@Component({
  selector: 'app-pedido-detalhe-body',
  templateUrl: './pedido-detalhe-body.component.html',
  styleUrls: ['./pedido-detalhe-body.component.css']
})
export class PedidoDetalheBodyComponent implements OnInit {

  @Input() config: any = {}
  pedido: any = {
    total: 0,
    endereco: {},
    mensagem_agradecimento: '',
    codigo_rastreio: ''
  }

  constructor(
    private route: ActivatedRoute,
    private carrinhoService: CarrinhoService,
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(
      params => {
        let token = params.get('token');
        this.getPedido(token)
      });
  }

  getPedido(token) {
    this.carrinhoService.getPedido(token).
      subscribe(
        data => {
          this.pedido = data
          if(this.pedido.link_boleto != ""){
            // window.open(this.pedido.link_boleto, '_blank');
          }
        },
        err => {
        }
      )
  }

}
