import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ClienteService } from '../services/cliente.service';
import swal from 'sweetalert';
import { LocalStorageService } from '../local-storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-body',
  templateUrl: './login-body.component.html',
  styleUrls: ['./login-body.component.css']
})
export class LoginBodyComponent implements OnInit {
  @ViewChild('closebutton') closebutton;

  email = ''
  senha = ''
  loader = false
  @Input() config: any = {}

  constructor(
    private clienteService: ClienteService,
    private localStorage: LocalStorageService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  login() {
    this.clienteService.login(this.email, this.senha)
      .subscribe(
        data => {
          this.localStorage.setCliente(data)
          swal("Sucesso", "Bem vindo " + data.nome + "!", "success")
            .then(() => {
              this.router.navigate(['/']);
            })
        },
        err => {
          if (err.status == 404) {
            swal("Erro", err.error, "error")
          } else {
            swal("Erro", "Algo deu errado!", "error")

          }
        }
      )
  }

  enviarEmail() {
    this.loader = true
    this.clienteService.redefinirSenha(this.email)
      .subscribe(
        data => {
          swal("Sucesso", "Se o email existir, será enviado uma nova senha!", "success")
          .then(() => {
            this.closebutton.nativeElement.click();
            this.loader = false
          })
        },
        err => {
          this.loader = false
          swal("Erro", "Algo deu errado!", "error")
        }
      )
  }

}
