import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'src/app/local-storage.service';
import { ConfigService } from 'src/app/services/config.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {

  config: any = {}
  tipoFrete = null
  constructor(
    private configService: ConfigService,
    private localStorage: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title
  ) {
    this.route.paramMap.subscribe(
      params => {
        this.tipoFrete = params.get('tipoFrete');
        console.log(this.tipoFrete)
      });
    this.getConfig()
  }

  ngOnInit(): void {
    this.getCliente()
  }

  getCliente(){
    let cliente = this.localStorage.getCliente()
    if(cliente != null){
      this.router.navigate(['/endereco', this.tipoFrete]);
    }
  }

  getConfig() {
    this.configService.config().subscribe(
      data => {
        this.config = data
        this.titleService.setTitle('Checkout | ' + data.nome)
      }, err => {
      }
    )
  }
}
