import { Component, OnInit } from '@angular/core';
import { ProdutosService } from '../services/produtos.service';

@Component({
  selector: 'app-todas-categorias',
  templateUrl: './todas-categorias.component.html',
  styleUrls: ['./todas-categorias.component.css']
})
export class TodasCategoriasComponent implements OnInit {

  categorias: any = []
  constructor(
    private produtoService: ProdutosService
  ) { }
  
  ngOnInit(): void {
    this.getCategorias()
  }

  getCategorias() {
    this.produtoService.getCategorias().subscribe(
      data => {
        this.categorias = data
      },
      err => {
      }
    )
  }

}
