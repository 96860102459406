<div class="hero_area2">
    <app-menu [config]="config"></app-menu>
    <!-- <app-carrossel [config]="config"></app-carrossel> -->
</div>

<section class="contact_section layout_padding">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="form_container">
                    <div class="heading_container">
                        <h2>
                            Contato
                        </h2>
                    </div>
                    <form [formGroup]="contatoForm" (submit)="salvar()">
                        <div>
                            <label for="">Nome</label>
                            <input class="form-control invalid" type="text" formControlName="nome"/>
                            <p *ngIf="contatoForm.controls['nome'].invalid && contatoForm.controls['nome'].errors.required" class="text-danger alert2">*Informe o nome</p>
                        </div>
                        <div>
                            <label for="">Email</label>
                            <input class="form-control" type="email" formControlName="email"/>
                            <p *ngIf="contatoForm.controls['email'].invalid && contatoForm.controls['email'].errors.required" class="text-danger alert2">*Informe o email</p>
                            <p *ngIf="contatoForm.controls['email'].invalid && contatoForm.controls['email'].errors.email" class="text-danger alert2">*Email inválido</p>

                        </div>

                        <div>
                            <label for="">Mensagem</label>
                            <textarea class="form-control" rows="4" formControlName="mensagem"></textarea>

                        </div>
                        <div class="d-flex ">
                            <button [disabled]="!contatoForm.valid" [style.background]="config.cor_btn" style="border: none;">
                                Enviar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-md-6">
                <div class="img-box">
                    <img src="{{config.contato_url}}" alt="">
                </div>
            </div>
        </div>
    </div>
</section>