import { Injectable } from '@angular/core';
import { Carrinho } from './models/carrinho';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private storage: Storage;
  constructor() {
    this.storage = window.localStorage;
  }

  setCarrinho(cart){
    if (this.storage) {
      this.storage.setItem("cart", JSON.stringify(cart));
      return true;
    }
    return false;
  }

  carrinhoClear(){
    this.storage.removeItem("cart")
  }

  logoffCliente(){
    this.storage.removeItem("client")

  }

  setItemCarrinho(item: Carrinho): boolean {
    let carrinho = this.getCarrinho()
    console.log(carrinho)
    if (this.storage) {
      this.itemAtritubuido(item, (cart) => {

        this.storage.setItem("cart", JSON.stringify(cart));
        return true;
      })
      return true;
    }
    return false;
  }

  getCarrinho(): any {
    if (this.storage) {
      if (this.storage.getItem("cart")) {
        return JSON.parse(this.storage.getItem("cart"));
      }
      return [];
    }
    return [];
  }

  itemAtritubuido(item, call) {
    let dup = false;
    let carrinho = this.getCarrinho()

    for (let i = 0; i < carrinho.length; i++) {
      if (carrinho[i].id == item.id) {
        dup = true;
        carrinho[i].quantidade += 1;
      }
    }

    if (dup == false) {
      carrinho.push(item)
    }
    call(carrinho)
  }

  deleteItem(item) {
    let dup = false;
    let carrinho = this.getCarrinho()
    let temp = []
    for (let i = 0; i < carrinho.length; i++) {
      if (carrinho[i].id != item.id) {
        temp.push(carrinho[i])
      }
    }

    setTimeout(() => {
      this.storage.setItem("cart", JSON.stringify(temp));
    }, 300)
  }

  setCliente(data) {
    if (this.storage) {
      this.storage.setItem("client", JSON.stringify(data));
      return true;
    }
    return false;
  }

  getCliente(): any {
    if (this.storage) {
      if (this.storage.getItem("client")) {
        return JSON.parse(this.storage.getItem("client"));
      }
      return null;
    }
    return null;
  }

}
