import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';
import { FormBuilder, Validators } from '@angular/forms';
import swal from 'sweetalert';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.css']
})
export class ContatoComponent implements OnInit {

  config: any = {}
  contatoForm: any;

  constructor(
    private configService: ConfigService,
    private formBuilder: FormBuilder,
    private titleService: Title
  ) {
    this.getConfig()
    this.createForm()
  }

  createForm() {
    this.contatoForm = this.formBuilder.group({
      nome: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      mensagem: [''],
    });
    // this.contatoForm = this.formBuilder.group({
    //   nome: ['marcos', [Validators.required]],
    //   email: ['marcos@bueno.com', [Validators.required, Validators.email]],
    //   mensagem: ['oi é um teste de contato'],
    // });
  }

  ngOnInit(): void {
  }

  getConfig() {
    this.configService.config().subscribe(
      data => {
        this.config = data
        this.titleService.setTitle('Contato | ' + data.nome)
      }, err => {
      }
    )
  }

  salvar() {
    if (this.contatoForm.value.mensagem.length < 10) {
      swal("", "Informe uma mensagem com no minimo 10 caracteres", "warning");
    } else {
      this.configService.salvarContato(this.contatoForm.value).subscribe(
        data => {
          swal("Sucesso", "Contato salvo, em breve retornaremos!", "success");
          this.contatoForm.reset()
        },
        err => {
          swal("Erro", "Algo errado ao salvar, tente mais tarde!", "error");

        }
      )
    }
  }

}
