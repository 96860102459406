import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-novidades',
  templateUrl: './novidades.component.html',
  styleUrls: ['./novidades.component.css']
})
export class NovidadesComponent implements OnInit {

  config: any = {}
  constructor(
    private configService: ConfigService,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.getConfig()
  }

  getConfig() {
    this.configService.config().subscribe(
      data => {
        this.config = data
        this.titleService.setTitle('Novidades | ' + data.nome)
      }, err => {
      }
    )
  }
}
