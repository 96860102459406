import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from '../services/config.service';
import { ProdutosService } from '../services/produtos.service';

@Component({
  selector: 'app-carrossel',
  templateUrl: './carrossel.component.html',
  styleUrls: ['./carrossel.component.css']
})
export class CarrosselComponent implements OnInit {
  carrossel: any = []
  @Input() config: any = {}
  @ViewChild('nextbutton') nextbutton;
  c2: any = {}
  constructor(
    private produtosService: ProdutosService,
    private configService: ConfigService,
    private router: Router
  ) { }

  getConfig() {
    this.configService.config().subscribe(
      data => {
        setInterval(() => {
          if (this.router.url == '/' || this.router.url == '/#cs') {
            this.nextbutton.nativeElement.click();
          }
        }, 1000 * data.timer_carrossel)
      }, err => {
      }
    )
  }

  ngOnInit(): void {
    this.getCarrossel()
    this.getConfig()

  }

  getCarrossel() {
    this.produtosService.getCarrossel().subscribe(
      data => {
        this.carrossel = data
        console.log(data)
      },
      err => {
      }
    )
  }


}
