import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-categoria',
  templateUrl: './categoria.component.html',
  styleUrls: ['./categoria.component.css']
})
export class CategoriaComponent implements OnInit {
  config: any = {}

  constructor(
    private configService: ConfigService,
    private titleService: Title
  ) {
    this.getConfig()
  }

  ngOnInit(): void {
  }

  getConfig() {
    this.configService.config().subscribe(
      data => {
        this.config = data
        this.titleService.setTitle('Categorias | ' + data.nome)
      }, err => {
      }
    )
  }

}
