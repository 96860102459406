<footer class="footer_section" [style.background]="config.cor_fundo">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-lg-3 footer-col">
                <div class="footer_detail">
                    
                    <div class="footer_social">
                        <a *ngIf="config.link_facebook != ''" href="{{config.link_facebook}}">
                            <i class="fa fa-facebook" aria-hidden="true"></i>
                        </a>
                        <a *ngIf="config.link_twiter != ''" href="{{config.link_twiter}}">
                            <i class="fa fa-twitter" aria-hidden="true"></i>
                        </a>
                        <a *ngIf="config.link_instagram != ''" href="{{config.link_instagram}}">
                            <i class="fa fa-instagram" aria-hidden="true"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-5 footer-col">
                <div class="footer_contact">
                    <h4>
                        Endereço
                    </h4>
                    <div class="contact_link_box">
                        <a href="">
                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                            <span>
                                {{config.rua}}, {{config.numero}} - {{config.bairro}} - {{config.cidade}}
                            </span>
                        </a>
                        <a href="">
                            <i class="fa fa-phone" aria-hidden="true"></i>
                            <span>
                                {{config.telefone}}
                            </span>
                        </a>
                        <a href="">
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                            <span>
                                {{config.email}}
                            </span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-3 footer-col">
                <div class="footer_contact">
                    <h4>
                        Se inscreva para receber ofertas
                    </h4>
                    <form [formGroup]="mailForm" (submit)="salvarEmail()">
                        <input style="border: 1px solid #fff; border-radius: 5px;" [style.background]="config.cor_fundo" type="email" formControlName="email" id="email" placeholder="Seu melhor email" />
                        <button [style.background]="config.cor_btn" style="border: none;" type="submit" [disabled]="!mailForm.valid">
                            Me inscrever
                        </button>
                    </form>
                </div>
            </div>
            <!-- <div class="col-md-6 col-lg-3 footer-col">
                <div class="map_container">
                    <div class="map">
                        <div id="googleMap"></div>
                    </div>
                </div>
            </div> -->
        </div>
        <div class="footer-info">
            <p class="text-white">
                &copy; <span id="displayYear"></span> {{config.nome}} Todos os direitos reservados
            </p>
        </div>
    </div>
</footer>