import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../services/config.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {
  config: any = {}

  constructor(
    private configService: ConfigService,
  ) {
    this.getConfig()
  }

  ngOnInit(): void {
  }

  getConfig() {
    this.configService.config().subscribe(
      data => {
        this.config = data
      }, err => {
      }
    )
  }

}
