<div class="container">

    <div class="contact_form">
        <div class="">
            <div class="row">
                <div class="col-lg-6 offset-lg-3">
                    <div class="contact_form_container">
                        <h3>
                            Informe suas credenciais
                        </h3>

                        <div id="contact_form">
                            <br>
                            <div class="contact_form_inputs d-flex">
                                <input style="width: 100%" type="text" id="contact_form_name" class="form-control"
                                    [(ngModel)]="email" placeholder="Email">
                            </div>
                            <br>

                            <div class="contact_form_inputs d-flex">
                                <input type="password" id="contact_form_name" class="form-control" [(ngModel)]="senha"
                                    placeholder="Senha">
                            </div>
                            <br>
                            <div class="contact_form_button">
                                <button (click)="login()" [style.background]="config.cor_btn"
                                    style="width: 100%; border: none; color: '#fff'" type="submit"
                                    class="btn btn-lg">Login</button>
                            </div>

                            <div class="row">
                                <div class="col-lg-12" style="margin-top: 5px; margin-bottom: 5px;">
                                    <a style="color: #fff;" class="btn btn-danger" data-toggle="modal"
                                        data-target="#modal-senha">Esqueci minha senha</a>
                                </div>
                            </div>
                        </div>


                        <div class="row" *ngIf="config.politica_privacidade != ''">
                            <div class="col-lg-12" style="margin-top: 10px;">

                                <a class="text-info" data-toggle="modal" data-target="#modal-politica">Politica de
                                    privacidade</a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modal-politica" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Politica de privacidade</h5>
            </div>
            <div class="modal-body">
                {{config.politica_privacidade}}
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">Fechar</button>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="modal-senha" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Redefinir senha</h5>
                <button type="button" class="close" #closebutton data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="contact_form_inputs d-flex">
                    <input type="text" id="contact_form_name" class="form-control" [(ngModel)]="email"
                        placeholder="Email">
                </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="loader || email == ''" style="color: #fff;" (click)="enviarEmail()" type="button" class="btn" [style.background]="config.cor_btn">
                    Enviar
                    <div *ngIf="loader" class="spinner-border" role="status">
                    </div>
                </button>
            </div>

        </div>
    </div>
</div>