import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-produto-detalhe',
  templateUrl: './produto-detalhe.component.html',
  styleUrls: ['./produto-detalhe.component.css']
})
export class ProdutoDetalheComponent implements OnInit {

  config: any = {}
  constructor(
    private configService: ConfigService,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.getConfig()
  }

  getConfig() {
    this.configService.config().subscribe(
      data => {
        this.config = data
        this.titleService.setTitle('Produto | ' + data.nome)
      }, err => {
      }
    )
  }

}
