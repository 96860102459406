<div class="container">

    <div class="">
        <h4>Olá, <strong>{{cliente.nome}}</strong></h4>

        <button style="color: #fff;" data-toggle="modal" data-target="#exampleModal" class="btn btn-warning">Alterar
            senha</button>
        <button style="margin-left: 4px;" (click)="logoff()" class="btn btn-danger">Logoff</button>

        <hr>

        <div class="row">
            <form [formGroup]="clientForm" (submit)="atualizarCliente()">
                <div class="col-12" style="margin-bottom: 10px;">
                    <h4>Dados pessoais</h4>
                    <br>
                    <div class="row">

                        <div class="col-lg-3 col-6">
                            <div class="checkout__input">
                                <label>Nome</label>
                                <input formControlName="nome" class="form-control" type="text">
                            </div>
                        </div>
                        <div class="col-lg-3 col-6">
                            <div class="checkout__input">
                                <label>Sobre nome</label>
                                <input formControlName="sobre_nome" class="form-control" type="text">
                            </div>
                        </div>

                        <div class="col-lg-3 col-6">
                            <div class="checkout__input">
                                <label>Telefone</label>
                                <input formControlName="telefone" class="form-control" mask="(00) 00000-0000"
                                    type="text">
                            </div>
                        </div>

                        <div class="col-lg-3 col-6">
                            <div class="checkout__input">
                                <label>Email</label>
                                <input formControlName="email" class="form-control" type="email">
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-12">
                            <button [style.background]="config.cor_btn" [disabled]="!clientForm.valid"
                                style="color: #fff; border: none;" type="submit" class="btn btn-primary">Salvar</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <hr>
        <div class="row">
            <div class="col-12" style="margin-bottom: 10px;">
                <h4>Seus pedidos</h4>
            </div>

            <div class="col-lg-4 col-md-6" *ngFor="let p of cliente.pedidos">
                <div class="char_item flex-row align-items-center justify-content-start">

                    <div class="char_content">
                        <ul>

                            <h4>Data: <strong>{{p.created_at | date:'dd/MM/YYYY'}}</strong></h4>
                            <h4>Total: <strong>{{ p.valor_total | currency:'BRL' }}</strong></h4>
                        </ul>

                        <span *ngIf="p.status_preparacao == 0" class="text-info">Novo</span>
                        <span *ngIf="p.status_preparacao == 1" class="text-primary">Aprovado</span>
                        <span *ngIf="p.status_preparacao == 2" class="text-danger">Cancelado</span>
                        <span *ngIf="p.status_preparacao == 3" class="text-warning">Aguardando Envio</span>
                        <span *ngIf="p.status_preparacao == 4" class="text-dark">Enviado</span>
                        <span *ngIf="p.status_preparacao == 5" class="text-success">Entregue</span>

                        <a [routerLink]="['/pedido-detalhe', p.token]" [style.background]="config.cor_btn"
                            class="btn btn-block" style="color: #fff;">
                            <i class="fa fa-list"></i>
                            Detalhes
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-12" *ngIf="cliente.pedidos.length == 0">
                <h3 class="text-danger">Nenhum pedido até o momento!</h3>
            </div>
        </div>

        <hr>
        <div class="row">
            <div class="col-12" style="margin-bottom: 10px;">
                <h4>Seus endereços cadastrados</h4>
            </div>

            <div class="col-12" style="margin-bottom: 10px;">
                <button data-toggle="modal" #openModal data-target="#modal-endereco" class="btn btn-success">
                    <i class="fa fa-plus"></i> Novo Endereço
                </button>
            </div>


            <div class="col-lg-4 col-md-6 end" *ngFor="let e of cliente.enderecos">
                <div class="char_item flex-row align-items-center justify-content-start" style="height: 200px;">

                    <div class="char_content">

                        <p>{{e.rua}}, {{e.numero}} - {{e.bairro}}</p>
                        <p>{{e.cidade}} - {{e.uf}}</p>
                        <p>{{e.cep}}</p>

                        <button style="color: #fff;" [style.background]="config.cor_btn" (click)="editEndereco(e)"
                            class="btn btn-block">
                            <i class="fa fa-edit"></i>
                            Editar
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <br>

</div>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Alterar Senha</h5>
                <button type="button" class="close" #closebutton data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-6 col-12">
                        <div class="checkout__input">
                            <label>Senha</label>
                            <input [(ngModel)]="senha" class="form-control" name="senha" value="" type="password">
                        </div>
                    </div>

                    <div class="col-lg-6 col-12">
                        <div class="checkout__input">
                            <label>Repita Senha</label>
                            <input [(ngModel)]="repitaSenha" class="form-control" name="repita_senha" value=""
                                type="password">
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">Fechar</button>
                <button type="button" (click)="alterarSenha()" class="btn btn-success">Salvar</button>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="modal-endereco" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <h5 class="modal-title" id="titulo">{{tituloModalEndereco}}</h5>
                <button type="button" class="close" #closebutton2 data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form [formGroup]="enderedoForm" (submit)="salvarEndereco()">
                <div class="modal-body">
                    <div class="row">

                        <input type="hidden" value="0" id="endereco_id" name="endereco_id">
                        <div class="col-lg-8 col-12">
                            <div class="checkout__input">
                                <label>Rua</label>
                                <input class="form-control" id="rua" formControlName="rua" type="text">
                            </div>
                        </div>
                        <div class="col-lg-4 col-12">
                            <div class="checkout__input">
                                <label>Nº</label>
                                <input class="form-control" formControlName="numero" type="text">
                            </div>
                        </div>

                        <div class="col-lg-6 col-12">
                            <div class="checkout__input">
                                <label>Bairro</label>
                                <input class="form-control" formControlName="bairro" type="text">
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="checkout__input">
                                <label>CEP</label>
                                <input class="form-control" mask="00000-000" formControlName="cep" type="text">
                            </div>
                        </div>

                        <div class="col-lg-8 col-12">
                            <div class="checkout__input">
                                <label>Cidade</label>
                                <input class="form-control" formControlName="cidade"type="text">
                            </div>
                        </div>
                        <div class="col-lg-4 col-12">
                            <div class="checkout__input">
                                <label>UF</label>
                                <select formControlName="uf" class="custom-select" style="margin-left: 0px;">
                                    <option value="{{u}}" *ngFor="let u of estados()">{{u}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="checkout__input">
                                <label>Complemento</label>
                                <input class="form-control" formControlName="complemento" type="text">
                            </div>
                        </div>

                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Fechar</button>
                    <button [disabled]="!enderedoForm.valid" type="submit" class="btn btn-success">Salvar</button>
                </div>
            </form>
        </div>
    </div>
</div>