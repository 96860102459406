import { Component, Input, OnInit } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  @Input() config: any = {}

  carrinho: any = []
  cliente: any = null
  constructor(
    private localStorage: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.getCarrinho()
    this.getCliente()
  }

  getCarrinho(){
    this.carrinho = this.localStorage.getCarrinho()
  }

  getCliente(){
    this.cliente = this.localStorage.getCliente()
  }

}
