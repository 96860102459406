import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CategoriaComponent } from '../pages/categoria/categoria.component';
import { HomeComponent } from '../pages/home/home.component';
import { LayoutComponent } from '../layout/layout.component';
import { ProdutosCategoriaComponent } from '../pages/produtos-categoria/produtos-categoria.component';
import { CarrinhoComponent } from '../pages/carrinho/carrinho.component';
import { ProdutoDetalheComponent } from '../pages/produto-detalhe/produto-detalhe.component';
import { LoginComponent } from '../pages/login/login.component';
import { ContatoComponent } from '../pages/contato/contato.component';
import { CheckoutComponent } from '../pages/checkout/checkout.component';
import { EnderecoComponent } from '../pages/endereco/endereco.component';
import { AreaClienteComponent } from '../pages/area-cliente/area-cliente.component';
import { PagamentoComponent } from '../pages/pagamento/pagamento.component';
import { PedidoFinalizadoComponent } from '../pages/pedido-finalizado/pedido-finalizado.component';
import { PedidoDetalheComponent } from '../pages/pedido-detalhe/pedido-detalhe.component';
import { NovidadesComponent } from '../pages/novidades/novidades.component';
import { PesquisaComponent } from '../pages/pesquisa/pesquisa.component';

const routes: Routes = [
  {
    path: '', redirectTo: 'shop', pathMatch: 'full'
  },
  {
    path: '', 
    component: LayoutComponent,
    children: [
      {path: '', component: HomeComponent},
      {path: 'categorias', component: CategoriaComponent},
      {path: 'categorias/:id', component: ProdutosCategoriaComponent},
      {path: 'carrinho', component: CarrinhoComponent},
      {path: 'produto/:id', component: ProdutoDetalheComponent},
      {path: 'login', component: LoginComponent},
      {path: 'contato', component: ContatoComponent},
      {path: 'checkout/:tipoFrete', component: CheckoutComponent},
      {path: 'endereco/:tipoFrete', component: EnderecoComponent},
      {path: 'area-cliente', component: AreaClienteComponent},
      {path: 'pagamento/:token', component: PagamentoComponent},
      {path: 'pedido-finalziado/:token', component: PedidoFinalizadoComponent},
      {path: 'pedido-detalhe/:token', component: PedidoDetalheComponent},
      {path: 'novidades', component: NovidadesComponent},
      {path: 'pesquisa', component: PesquisaComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
