import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-pedido-detalhe',
  templateUrl: './pedido-detalhe.component.html',
  styleUrls: ['./pedido-detalhe.component.css']
})
export class PedidoDetalheComponent implements OnInit {

  config: any = {}
  constructor(
    private configService: ConfigService,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.getConfig()
  }

  getConfig() {
    this.configService.config().subscribe(
      data => {
        this.config = data
        this.titleService.setTitle('Pedido | ' + data.nome)
      }, err => {
      }
    )
  }

}
