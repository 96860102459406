import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ConfigService } from '../services/config.service';
import swal from 'sweetalert';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  @Input() config: any = {}

  mailForm: any;
  constructor(
    private configService: ConfigService,
    private formBuilder: FormBuilder
  ) {
    this.createForm()

  }

  ngOnInit(): void {
    // this.getConfig()
  }

  createForm() {
    this.mailForm = this.formBuilder.group({
      email: ['', [Validators.email, Validators.required]],
    });
  }

  getConfig() {
    this.configService.config().subscribe(
      data => {
        this.config = data
      }, err => {
      }
    )
  }

  salvarEmail() {
    this.configService.salvarEmail(this.mailForm.value.email).subscribe(
      data => {
        swal("Sucesso", "Email cadastrado!!", "success");

      }, err => {
        if (err.status == 404) {
          swal("Erro", err.error, "warning");
        } else {
          swal("Erro", "Erro desconhecido!!", "warning");
        }
      }
    )

  }

}
