import { Component, Input, OnInit } from '@angular/core';
import { ProdutosService } from '../services/produtos.service';

@Component({
  selector: 'app-pesquisa-body',
  templateUrl: './pesquisa-body.component.html',
  styleUrls: ['./pesquisa-body.component.css']
})
export class PesquisaBodyComponent implements OnInit {

  produtos: any = [{}]
  pesquisa = ''
  @Input() config: any = {}

  constructor(
    private produtoService: ProdutosService,
  ) { }

  ngOnInit(): void {

    this.getProdutosNovos()
  }

  getProdutosNovos() {
    this.produtoService.getProdutosMaisVendidos().subscribe(
      data => {
        this.produtos = data
      }, err => {
      }
    )
  }

  pesquisaUp() {
    if (this.pesquisa.length > 0) {
      this.produtoService.pesquisa(this.pesquisa).subscribe(
        data => {
          this.produtos = data
        }, err => {
        }
      )
    }
  }

}
