import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { MenuComponent } from './menu/menu.component';
import { CarrosselComponent } from './carrossel/carrossel.component';
import { ProdutosDestaqueComponent } from './produtos-destaque/produtos-destaque.component';
import { CategoriasDestaqueComponent } from './categorias-destaque/categorias-destaque.component';
import { FooterComponent } from './footer/footer.component';
import { HttpClientModule } from '@angular/common/http';
import ptBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutComponent } from './layout/layout.component';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { HomeComponent } from './pages/home/home.component';
import { CategoriaComponent } from './pages/categoria/categoria.component';
import { TodasCategoriasComponent } from './todas-categorias/todas-categorias.component';
import { ProdutosCategoriaComponent } from './pages/produtos-categoria/produtos-categoria.component';
import { CarrinhoComponent } from './pages/carrinho/carrinho.component';
import { CarrinhoBodyComponent } from './carrinho-body/carrinho-body.component';
import { SafePipe } from './safe.pipe';
import { ProdutoCategoriaBodyComponent } from './produto-categoria-body/produto-categoria-body.component';
import { ProdutoDetalheComponent } from './pages/produto-detalhe/produto-detalhe.component';
import { ProdutoDetalheBodyComponent } from './produto-detalhe-body/produto-detalhe-body.component';
import { LoginComponent } from './pages/login/login.component';
import { LoginBodyComponent } from './login-body/login-body.component';
import { ContatoComponent } from './pages/contato/contato.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { CheckoutBodyComponent } from './checkout-body/checkout-body.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { EnderecoComponent } from './pages/endereco/endereco.component';
import { EnderecoBodyComponent } from './endereco-body/endereco-body.component';
import { AreaClienteComponent } from './pages/area-cliente/area-cliente.component';
import { AreaClienteBodyComponent } from './area-cliente-body/area-cliente-body.component';
import { PagamentoComponent } from './pages/pagamento/pagamento.component';
import { PagamentoBodyComponent } from './pagamento-body/pagamento-body.component';
import { PedidoFinalizadoComponent } from './pages/pedido-finalizado/pedido-finalizado.component';
import { PedidoFinalizadoBodyComponent } from './pedido-finalizado-body/pedido-finalizado-body.component';
import { PedidoDetalheComponent } from './pages/pedido-detalhe/pedido-detalhe.component';
import { PedidoDetalheBodyComponent } from './pedido-detalhe-body/pedido-detalhe-body.component';
import { LoaderComponent } from './loader/loader.component';
import { NovidadesComponent } from './pages/novidades/novidades.component';
import { NovidadesBodyComponent } from './novidades-body/novidades-body.component';
import { PesquisaComponent } from './pages/pesquisa/pesquisa.component';
import { PesquisaBodyComponent } from './pesquisa-body/pesquisa-body.component';

registerLocaleData(ptBr);
@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    CarrosselComponent,
    ProdutosDestaqueComponent,
    CategoriasDestaqueComponent,
    FooterComponent,
    LayoutComponent,
    HomeComponent,
    CategoriaComponent,
    TodasCategoriasComponent,
    ProdutosCategoriaComponent,
    CarrinhoComponent,
    CarrinhoBodyComponent,
    SafePipe,
    ProdutoCategoriaBodyComponent,
    ProdutoDetalheComponent,
    ProdutoDetalheBodyComponent,
    LoginComponent,
    LoginBodyComponent,
    ContatoComponent,
    CheckoutComponent,
    CheckoutBodyComponent,
    EnderecoComponent,
    EnderecoBodyComponent,
    AreaClienteComponent,
    AreaClienteBodyComponent,
    PagamentoComponent,
    PagamentoBodyComponent,
    PedidoFinalizadoComponent,
    PedidoFinalizadoBodyComponent,
    PedidoDetalheComponent,
    PedidoDetalheBodyComponent,
    LoaderComponent,
    NovidadesComponent,
    NovidadesBodyComponent,
    PesquisaComponent,
    PesquisaBodyComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgxMaskModule.forRoot()
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'pt' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
