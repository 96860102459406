import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  SERVER_URL = ''

  constructor(private http: HttpClient) {
    this.SERVER_URL = environment.url
  }

  public salvar(cliente) {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${environment.token}`
    });
    return this.http.post(this.SERVER_URL + '/api/clientes/salvar', { cliente: cliente }, { headers: reqHeader });
  }

  public atualizar(cliente) {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${environment.token}`
    });
    return this.http.post(this.SERVER_URL + '/api/clientes/atualizar', { cliente: cliente }, { headers: reqHeader });
  }

  public salvarEndereco(data, token) {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${environment.token}`
    });
    return this.http.post(this.SERVER_URL + '/api/enderecos/salvar', { endereco: data, token: token }, { headers: reqHeader });
  }

  public atualizarEndereco(data, id) {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${environment.token}`
    });
    return this.http.post(this.SERVER_URL + '/api/enderecos/atualizar', { endereco: data, id: id }, { headers: reqHeader });
  }

  public login(email, senha):any {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${environment.token}`
    });
    return this.http.post(this.SERVER_URL + '/api/clientes/login', { email: email, senha: senha },
      { headers: reqHeader });
  }

  public redefinirSenha(email):any {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${environment.token}`
    });
    return this.http.post(this.SERVER_URL + '/api/clientes/esqueciMinhaSenha', { email: email },
      { headers: reqHeader });
  }

  public cadastroDuplicado(data) {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${environment.token}`
    });
    return this.http.post(this.SERVER_URL + '/api/clientes/cadastroDuplicado', { data: data }, { headers: reqHeader });
  }

  public findWithCart(token, carrinho): any {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${environment.token}`
    });
    return this.http.get(this.SERVER_URL + '/api/clientes/findWithCart',
      { headers: reqHeader, params: { token: token, carrinho: carrinho } });
  }

  public findWithData(token): any {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${environment.token}`
    });
    return this.http.get(this.SERVER_URL + '/api/clientes/findWithData',
      { headers: reqHeader, params: { token: token } });
  }

  public alterarSenha(token, novaSenha) {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${environment.token}`
    });
    return this.http.post(this.SERVER_URL + '/api/clientes/alterarSenha', { token: token, novaSenha: novaSenha },
      { headers: reqHeader });
  }

}
